<template>
   <section class=" px-4 w-full bg-white h-full pt-6">
    <div class="m-auto items-center text-center bg-white z-10 p-6 rounded-xl lg:max-w-xl w-full">
      <div><h1 class="text-4xl lg:text-4xl font-bold">Account Confirmed!</h1></div>
      <div><p class="text-md lg:text-xl my-3">
        Choose a location for your alerts
      </p></div>
      <div v-if="!locInfo.place_id">
        <input type="text" v-model="query" placeholder="e.g. Sebastian Inlet" id="location-input" autofocus="true" class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 mx-auto p-2.5"/>
        <ul class="mx-auto w-4/5 max-w-md text-left " >
          <li v-for="item in suggestions" :key="item.place_id" @click="setPlace(item.place_id)">
            <a href="#" class="block max-w-sm p-4 my-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
              <p class="font-normal text-gray-700">{{ item.description }}</p>
            </a>
          </li>
        </ul>
      </div>
      <div v-else class="text-center w-4/5 max-w-md mx-auto">
        <div class="my-2 mb-4">
          <a href="#" class="block max-w-sm p-4 my-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
            <p class="font-normal text-gray-700">{{ locInfo.formatted }}</p>
          </a>
        </div>
        <button @click="submit" :disabled="busy" class="text-white font-bold py-2 px-4 rounded text-2xl" :class="{'bg-blue-500' : !busy, 'bg-gray-300' : busy}">
          {{ busy ? 'Transporting...' : 'Set Location' }}
        </button>
        <div class="mt-4">
          <a class="text-blue-400" @click="reset"> Choose Another Location </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
import { usePlacesAutocomplete } from 'vue-use-places-autocomplete'
import { geocodeByPlaceId } from 'vue-use-places-autocomplete'

const store = TiderStore();
const router = useRouter();

const code = ref('');
const query = ref('')
var results = ref({})

const locInfo = reactive({
  place_id: '',
  formatted: '',
  lat: '',
  lng: '',
  zip: '',
  city: '',
  state: '',
  country: '',
  timezone: '',
  language: ''
})

onMounted(()=> {
  setTimeout(()=> {
    document.getElementById('location-input').focus()
  }, 100)
})

function reset() {
  console.log("Reset", locInfo)
  locInfo.place_id = ''
  locInfo.formatted = ''
  locInfo.lat = ''
  locInfo.lng = ''
  locInfo.zip = ''
  locInfo.city = ''
  locInfo.state = ''
  locInfo.country = ''
  locInfo.timezone = ''
  locInfo.language = ''
  query.value = ''
  results = []
  console.log("Done resetting")
}
const {
  suggestions,
  loading,
  sessionToken,
  refreshSessionToken
} = usePlacesAutocomplete(query, {
  debounce: 500,
  minLengthAutocomplete: 3,
  apiKey: 'AIzaSyCX2k_ou6zCS7HiDDT59CpD1HVP6gK_xkk'
})

const setPlace = async (item) => {
  console.log("Async setPlace", item);
  locInfo.place_id = item
  results = await geocodeByPlaceId(item)
  const obj = results[0]
  console.log(obj)
  console.log(obj.geometry.location.lat())
  console.log(obj.geometry.location.lng())
  locInfo.lat = obj.geometry.location.lat()
  locInfo.lng = obj.geometry.location.lng()
  locInfo.formatted = obj.formatted_address
  obj.address_components.forEach((entry)=> {
    if (entry.types.includes('postal_code')) {
      locInfo.zip = entry.long_name
    } else if (entry.types.includes('locality')) {
      locInfo.city = entry.long_name
    } else if (entry.types.includes('administrative_area_level_1')) {
      locInfo.state = entry.long_name
    } else if (entry.types.includes('country')) {
      locInfo.country = entry.long_name
    }
    locInfo.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    locInfo.language = navigator.language || navigator.userLanguage; 
  })

  console.log(locInfo)
  // zip = i.postalCode
  // city = i.city
  // state = i.state
  // country = i.country
}

const busy = ref(false)
const submit = () => {
  busy.value = true
  store.setLocation(locInfo).then((response)=> {
    console.log("Location Set", response.data)
    setTimeout(()=> {
      router.push("/finish")
    }, 50)
  })
}

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}

</script>
