<template>
  <section class="flex h-screen px-3 lg:px-6">
    <Transition>

    <div v-if="showing" class="m-auto h-screen overflow-y-auto pt-20 mb-20 items-center text-left bg-white z-10 p-6 rounded-xl xs:max-w-md max-w-2xl lg:p-12 shadow-2xl ">
      <div>
        <h1 class="text-5xl leading-tight font-bold">Terms of Service</h1></div>

        <h2>Welcome to TideTexts!</h2>
        <p>TideTexts ("we," "our," or "us") provides a free service that sends daily SMS messages with local tidal and weather information to users ("you" or "your"). By using our service, you agree to comply with and be bound by the following Terms of Service. Please read these terms carefully.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By using TideTexts, you agree to these Terms of Service. If you do not agree with any part of these terms, you must not use our service.</p>

        <h2>2. Service Description</h2>
        <p>TideTexts provides daily SMS messages containing local tidal and weather information. The service is free of charge, but standard message and data rates from your mobile carrier may apply. Additionally, we may send occasional sponsored messages alongside the daily tide reports. You may opt out of this service at any time by sending the word "STOP" in response to any message.</p>

        <h2>3. User Responsibilities</h2>
        <p>You agree to:</p>
        <ul>
            <li>- Provide accurate and up-to-date information when signing up for our service.</li>
            <li>- Notify us immediately of any unauthorized use of your phone number or account.</li>
            <li>- Use the service for personal, non-commercial purposes only.</li>
        </ul>

        <h2>4. Privacy Policy</h2>
        <p>Your privacy is important to us. Please review our <a href="/privacy" class="underline">Privacy Policy</a>, which explains how we collect, use, and protect your personal information. By using TideTexts, you consent to our collection and use of personal information as outlined in our Privacy Policy. We will not share your information with any third parties without your explicit consent.</p>

        <h2>5. Modifications to Service</h2>
        <p>We reserve the right to modify or discontinue the service, temporarily or permanently, with or without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of the service.</p>

        <h2>6. Termination</h2>
        <p>We may terminate or suspend your access to the service at any time, without prior notice or liability, for any reason, including if you breach these Terms of Service.</p>

        <h2>7. Disclaimer of Warranties</h2>
        <p>The service is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the accuracy, reliability, or availability of the service.</p>

        <h2>8. Limitation of Liability</h2>
        <p>In no event shall TideTexts be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use or inability to use the service.</p>

        <h2>9. Indemnification</h2>
        <p>You agree to indemnify and hold harmless TideTexts, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorney's fees, arising out of or in any way connected with your use of the service.</p>

        <h2>10. Governing Law</h2>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which TideTexts operates, without regard to its conflict of law principles.</p>

        <h2>11. Changes to Terms of Service</h2>
        <p>We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page, and your continued use of the service after such changes have been made will constitute your acceptance of the new terms.</p>

        <h2>12. Contact Information</h2>
        <p>If you have any questions about these Terms of Service, please contact us at:</p>
        <p>Email: hey@tidetexts.com</p>

        <p>Thank you for using TideTexts!</p>
        <p>TideTexts is owned and operated by Teakwood Partners LLC.</p>
        <p>This Terms of Service agreement is effective as of July 16th, 2024.</p>
    </div>
  </Transition>

  </section>
  <div id="images" class="absolute w-full h-full left-0 top-0 text-center text-2xl lg:text-4xl opacity-60">
    <div v-for="row in 20" class="grid grid-cols-12" style="height: 5%">
      <div v-for="i in 12" class="justify-center align-center emoji flex items-center" :style="{ animationDelay: `${i * 0.2}s` }"> {{ i%2== row%2 ? shuffle(emojis)[i] : '' }} </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const store = TiderStore();
const router = useRouter();

var showing = ref(false)

const phone = ref('');
var validation = ref({});
var valid = ref(false);
var loading = ref(false);

const emojis = [
  '🌊', 
  '🛥️', 
  '🦈', 
  '🤿', 
  '🏄‍♂️', 
  '🎣', 
  '⛵️', 
  '🐠', 
  '😎', 
  '🐬', 
  '🐙', 
  '🦐', 
  '☀️', 
  '🌚', 
  '🌙', 
  '🍣', 
  '🏄', 
  '🦀',
  '🦭',
  '🏖️',
  '👙',
  '🧜',
  '🥥',
  '🦑',
  '🐋',
  '🛶',
  '🌎',
  '🌝',
  '🌜',
  '🌛',
  '🌞',
]


const submit = () => {
  loading = true
  store.createTider({ phone: validation.number }).then((response)=> {
    router.push('/confirm')
  })
}

const shuffle = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
  }

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}


onMounted(() => {
  setTimeout(() => {
    showing.value = true
    console.log("Showing now " + showing)
  }, 10)
})

</script>

<style scoped>
  p {
    margin: 20px 0;
  }
  h2 {
    font-weight: bold;
    margin-top: 20px;
  }
  .emoji {
      border: none;
      background: none;
      animation: wave 8s ease-in-out infinite;
      transition: transform 1s ease, background-image 1s ease;
    }

    .v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


    @keyframes wave {
  0%, 100% {
    transform: translate(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(-20px);
  }
}
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
    }

    70% {
      transform: scale(1);
      /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    }

    100% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
  }
</style>