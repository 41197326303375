<template>
  <section class="h-full w-full px-4 bg-white pt-6">
    <div class="m-auto items-center text-center bg-white z-10 p-6 rounded-xl xs:max-w-md lg:max-w-xl">
      <div><h1 class="text-3xl lg:text-4xl font-bold">Confirm Account</h1></div>
      <div><p class="text-sm lg:text-xl my-3">
        Enter the 4 digit code we just sent you.
      </p></div>
      <div class="w-full max-w-4/5 my-3">
        <input v-model="code" type="text" id="code" autofocus="true" autocomplete="off" pattern="[0-9]*" class="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="4 digit code" required />
      </div>
      <div class="my-3">
        <button :disabled="code.toString().length != 4" @click="submit" class="text-white font-bold py-2 px-4 rounded text-2xl" :class="{'bg-blue-500' : code.toString().length == 4, 'bg-gray-300' : code.toString().length != 4}">
          Submit 
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { TiderStore } from "@/front/stores/tider_store.js";
const store = TiderStore();
const router = useRouter();

const phone = ref('3215141160');
const code = ref('');

const submit = () => {
  store.verify({ code: code.value }).then((response)=> {
    console.log("Tider created", response.data)
    router.push('/setup')
  })
}

const handleValidate = (e) => {
  console.log("Validate", e);
  valid = e.valid
  validation = e
}

</script>
